import * as React from "react"
import SiteLayout from "../components/SiteLayout";
import {graphql, Link} from "gatsby";

const TagsPage = ({data}) => {
  const tags = data.allMdx.group;

  return (
    <SiteLayout>
      <h2 className={`text-2xl pb-4`}>Tags</h2>

      <ul>
          {tags.map((tag) => (
            <li>
              <Link key={tag} to={`/tags/${tag.fieldValue}`}>{ tag.fieldValue } - { tag.totalCount } Articles</Link>
            </li>
          ))}
      </ul>

    </SiteLayout>
  )
}

export const tagsQuery = graphql`
query Tags {
  allMdx(sort: {order: DESC, fields: frontmatter___date}) {
    group(field: frontmatter___tags) {
      fieldValue
      totalCount
    }
  }
}
`

export default TagsPage
